import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Footer from "../components/footer"
import InspectionsBanner from "../components/inspectionsbanner"
import CallToAction from '../components/calltoaction'
import Coupon from "../components/coupon"

const CommercialServices = () => (
  <Layout>
    <SEO
        title="Rayburn's Roofing: Commercial Services"
        description="Rayburn's Roofing provides top-quality work for homeowners and businesses alike. This is a list of a few of the commercial services we offer."
    />
    <InspectionsBanner />

    <main class="mt-10 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
        <div class="sm:text-center lg:text-left">
          <h2 class="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
            COMMERCIAL – FLAT – METAL – ROOFING PROFESSIONALS
          </h2>
          <p class="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
            Whether you own a retail store, office building, or any other type of commercial building, Rayburn’s Roofing can help with all of your roofing needs. Our experienced roofing team will take into account any factors that may impact the life and function of your commercial roof and, together, we can help you decide which roof system is right for your business. A staff member will be on site for every phase of your project, ensuring completion with top-notch quality. We prioritize your roofing needs and can transform your roof from an unpredictable expense into a proactively managed asset. We utilize the industry’s highest quality materials and exceed manufacturer standards. Our experienced contractors collaborate to ensure any roof repair, installation, or construction is done correctly the first time, saving you money in deferred roof expenses, helping to reduce your energy costs, and increasing the longevity of your roof. Our professional crew will complete your roofing project safely and with minimal disruption to your facility.
          </p>
        </div>
    </main>

    <main class="mt-10 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
        <div class="sm:text-center lg:text-left">
          <h2 class="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
            TPO
          </h2>
          <p class="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
            Thermoplastic polyolefin (TPO) single-ply roofing membranes are among the fastest growing commercial roofing products and have gained praise across the industry for their many performance and installation advantages. As demand increases for heat-reflective and energy efficient roofing systems, you can rely on TPO single-ply roofing membranes to continue to provide resistance to ultraviolet, ozone and chemical exposure. TPO has a variety of adherent options and resilience to elements such as:
          </p>
            <ul class="list-disc ml-6 text-gray-500">
                <li>Lower roof surface temps</li>
                <li>Energy efficiency</li>
                <li>Superior puncture resistance</li>
                <li>Maintains flexibility in cold weather</li>
                <li>High wind durability</li>
                <li>Simplified installation</li>
            </ul>
        </div>
    </main>

    <main class="mt-10 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
        <div class="sm:text-center lg:text-left">
          <h2 class="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
            EPDM
          </h2>
          <p class="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
            PDM (ethylene propylene diene terpolymer) is a durable synthetic rubber roofing membrane. It can be installed fully adhered, mechanically attached or ballasted, with the seams of the roofing system sealed using liquid adhesives or specially formulated tape. EPDM is proven to have the longest service life over every other system in the roofing industry. This is due to factors such as:
          </p>
          <ul class="list-disc ml-6 text-gray-500">
            <li>Flexibility in all temperatures</li>
            <li>Thermal shock durability</li>
            <li>Weathering and abrasion</li>
            <li>Resistance to wind damage 120mph and greater</li>
            <li>Ultraviolet radiation resistance</li>
            <li>Superior resistance to extreme heat and fire</li>
          </ul>
          <p class="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
            EPDM Rubber roofing membrane has been the leading choice for contractors, architects, and building owners for low sloped roofing projects for almost 50 years. At Rayburn’s Roofing, our relationship does not end when your project is completed, it begins.
          </p>
        </div>
    </main>

    <br />
    <hr />
    <CallToAction />
    <Footer />
    <Coupon />
  </Layout>
)

export default CommercialServices
